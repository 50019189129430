import * as React from "react"
import Layout from "../components/layout"
import Banner from "../components/Index/banner"
import Summary from "../components/Index/summary"
import About from "../components/Index/about"
import VidModal from "../components/Shared/vidmodal"
import MailingWrap from "../components/Shared/mailingwrap"
import IndexGive from "../components/Index/indexGive"
import pastorsPic from "../images/noah:lidiah.png"

const IndexPage = () => {
  return (
    <Layout>
    <Banner />
    <Summary />
    <About />
    {/* <VidModal 
      title="Our Pastors"
      caption="Hear the heart behind what we are all about at Amplified. From our very own Noah and Lidiah Mogesse. Senior Pastor/ Apostle/ Shepherd/ Man and Woman of God."
      link="https://www.youtube.com/embed/IkrrPcbjlSM"
      pic={pastorsPic}
      alt="Meet-The-Pastors-Video"
      align={1} //0 is align left
    /> */}
    <IndexGive />
    <MailingWrap />

    </Layout>
  )
}

export default IndexPage
