import React, {useEffect} from "react"
import styled from "styled-components"
import { useInView } from "react-intersection-observer"
import { motion, useAnimation } from "framer-motion"
import World from "../../images/svg/world.svg"

const Summary = () => {
    const controls = useAnimation()
    const [ref, inView] = useInView({
    // Percentage of item in view to trigger animation
    threshold: 0.25,
  })

  useEffect(() => {
    if (inView) {
      controls.start("visible")
    }
  }, [controls, inView])

    return(
        <motion.div
      ref={ref}
      animate={controls}
      initial="hidden"
      variants={{
        visible: { opacity: 1, y: 0 },
        hidden: { opacity: 0, y: 25 },
      }}
      transition={{ ease: "easeOut", duration: 1.25, delay: 0.15 }}
    >
        <SummaryWrapper className="text-color-black container padding-top-bottom-60 pos-rel">
            <div className="center text-overlay">
              <h1 className="subhead-xxxl" style={{backgroundColor:`#f8f4f1`}}>
                  We are a collection of  <span className="text-color-red">Jesus followers</span> marked by His love with a passion to see it transform our communities.
              </h1>
            </div>
            <World className="world" />
        </SummaryWrapper>
    </motion.div>
    )
}
const SummaryWrapper = styled.div`
.world{
  padding-top: 15rem;
  height: auto;
  filter: invert(.6);
  z-index: -1;

  @media only screen and (max-width: 767px){
    position: inherited;
    bottom: -5%;
  }
}

.text-overlay {
  z-index: 1;
}
`

export default Summary