import React, { useEffect } from "react"
import styled from "styled-components"
import { useInView } from "react-intersection-observer"
import { motion, useAnimation } from "framer-motion"
import GiveButton from "../Shared/giveButton"
import Coffee from "../../images/svg/coffee.svg"
import Candle from "../../images/svg/candle.svg"

const IndexGive = () => {
    const controls = useAnimation()
    const [ref, inView] = useInView({
    // Percentage of item in view to trigger animation
    threshold: 0.25,
  })

  useEffect(() => {
    if (inView) {
      controls.start("visible")
    }
  }, [controls, inView])
    return(
    <motion.div
      ref={ref}
      animate={controls}
      initial="hidden"
      variants={{
        visible: { opacity: 1, y: 0 },
        hidden: { opacity: 0, y: 25 },
      }}
      transition={{ ease: "easeOut", duration: 1.25, delay: 0.15 }}
    >
        <IndexGiveWrapper className="padding-bottom-120">
            <div className="height-100perc index-give padding-top-bottom-120 pos-rel">
              {/* <Coffee className="graphic coffee"/> */}
              <div className="support-card center">
                <div className="unblur" >
                  <h1 className="subhead-m text-color-black give-title" >Support The Mission</h1>
                  <p className="text-color-black give-p">We are able to do what we do because of your contributions. Thank you so much for sowing into what God is doing here at Amplified!</p>
                  <GiveButton />
                </div>
              </div>
              <Candle className="graphic candle"/>
            </div>
        </IndexGiveWrapper>
        </motion.div>
    )
}

const IndexGiveWrapper = styled.div`
margin: 10rem 0;
@media only screen and (max-width: 767px){
  margin-top: 2rem;
}

.index-give {
    text-align: center;
    height: 50vh;
}
.support-card{
  width: auto;
  padding: 7% 3%;
  border-radius: 25px;
  z-index: 1;
}

.graphic{
  filter: invert(.6);
  z-index: -1;
  position: relative;
}
.coffee{
  bottom: 150%;
  right: 20%;
  width: 50rem;
  height: 30rem;
    @media only screen and (max-width: 850px){
      display: none;
    }
}

.candle{
  height: 45rem;
  right: 10%;
  @media only screen and (max-width: 850px){
    height: 30rem;
  }
}

.give-title{
  //background-color: #f8f4f1;
  margin: auto
}

.give-p{
  background-color: #f8f4f1;
  margin: 5% auto;
  border-radius: 25px;
}
`
export default IndexGive