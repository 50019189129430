import React, {useEffect} from "react"
import styled from "styled-components"
import { useInView } from "react-intersection-observer"
import { motion, useAnimation } from "framer-motion"
import pic2 from "../../images/AmplifiedWebsiteContent/5G0A8487.jpg"
import Light from "../../images/svg/light.svg"

const About = () => {
    const controls = useAnimation()
    const [ref, inView] = useInView({
    // Percentage of item in view to trigger animation
    threshold: 0.25,
  })

  useEffect(() => {
    if (inView) {
      controls.start("visible")
    }
  }, [controls, inView])
    return (
        <AboutWrapper>
            <div className="pos-rel padding-top-bottom-120 back text-color-white" style={{backgroundColor: `black`}}>
                <motion.div
                    ref={ref}
                    animate={controls}
                    initial="hidden"
                    variants={{
                        visible: { opacity: 1, y: 0 },
                        hidden: { opacity: 0, y: 25 },
                    }}
                    transition={{ ease: "easeOut", duration: 1.25, delay: 0.15 }}
                    >
                    <div className="container flex-container padding-bottom-70">
                        <div className="six-columns padding-bottom-20">
                            <div className="swiper-slide pic-rightalign--uno">
                                <img src={pic2} alt="About us" />
                            </div>
                        </div>

                        <div className="six-columns  padding-bottom-20 text-color-white">
                            <h2 className="column-l-r-margin-10 subhead-xxl align-center padding-bottom-20">
                                <span className="hidden-box d-block">
                                    Our Values
                                </span>
                            </h2>
                            <div className="column-l-r-margin-10">
                                <p className="body-text-s">
                                    At Amplified we are all about loving God and people well. We believe that Jesus' death and resurrection provided salvation and benefits to all who place their trust in Christ
                                    alone for salvation. We know that any life can be transformed through the work of Christ and we desire for our city to encounter the life that comes through His Spirit. We believe all Christians have a 
                                    mandate to live missional lives but we understand that we need the power of the Holy Spirit to be able to exemplify God's kingdom and accomplish the mission we are 
                                    called to live out. 
                                </p>

                                <ul className="list text-color-white margin-top-30">
                                    <li className="list__item white dot hidden-box">
                                        <div className="subhead-xs">We honour the Bible</div>
                                    </li>
                                    <li className="list__item white dot hidden-box">
                                        <div className="subhead-xs">We believe in the Trinity</div>
                                    </li>
                                    <li class="list__item white dot hidden-box">
                                        <div className="subhead-xs anim-slide tr-delay-02">We believe all believers receive new life through Jesus' death and resurrection</div>
                                    </li>
                                    <li className="list__item white dot hidden-box">
                                        <div className="subhead-xs anim-slide tr-delay-03">We know by faith and experience that the Holy Spirit is active and present in today's world</div>
                                    </li>
                                    <li className="list__item white dot hidden-box">
                                        <div className="subhead-xs anim-slide tr-delay-04">We believe that we are all commisioned by Christ to preach the gospel and serve our fellow man</div>
                                    </li>
                                </ul>
                                <Light className="candle"/>
                            </div>
                        </div>
                    </div>
                </motion.div>
            </div>
        </AboutWrapper>
    )
}
const AboutWrapper = styled.div`
margin-top: 20%;
@media only screen and (max-width: 767px){
    margin-top: 55%;
}

.back{
    border-radius: 25px;
    margin: 1%;
}
.about-slider .swiper-wrapper {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: end;
	   -ms-flex-align: end;
	      align-items: flex-end;
}
.padded{
    padding: 10%;
}
.align-center{
    text-align: center;
}
.pic-rightalign--uno{
    position: relative;
    text-align: right!important;
    width: 75%;
    height: auto;
    left: 0;
    margin-left: 15%;
}
.pic-rightalign--dous{
    position: relative;
    text-align: right!important;
    width: 35rem;
    padding-bottom: 15%;
    height: auto;
    left: 0;
    margin-right: 5%;
}
.candle{
    filter: invert(80%);
    height: 15rem;
    padding: 10% 0 0 10%;
}
`

export default About