import React from "react"
import styled from "styled-components"
import { motion } from "framer-motion"
import pursuit from "../../images/pursuit.gif"

const Banner = () => {
    const variants = {
        visible: { opacity: 1, y: 0 },
        hidden: { opacity: 0, y: 20 },
      }

    return (
        <motion.div
            initial="hidden"
            animate="visible"
            variants={variants}
            transition={{ ease: "easeOut", duration: 0.8, delay: 1.2 }}
        >
            <BannerWrapper>
                <div id="banner" className="flex-min-height-100vh pos-rel">
                    <div className="container padding-top-bottom-120 text-color-black pos-rel">
                        <h2 className="headline-l heading text-overlay">
                            <div className="flex-container title">
                            <span className="hidden-box d-block pad-left-right">
                                LOVE GOD.
                            </span>
                            <span className="hidden-box d-block pad-left-right">
                                LOVE PEOPLE. 
                            </span>
                            <span className="hidden-box d-block pad-left-right">
                                SERVE PEOPLE.
                            </span>
                            </div>
                            
                        </h2>
                        <img class="front-graphic" src={pursuit} alt="art"/> 
                    </div>
                </div>
            </BannerWrapper>
        </motion.div>
    )
}

const BannerWrapper = styled.div`
.heading{
    z-index: 1;
    text-align: center;
    position: absolute;
    width: 100%;
    bottom: 95%;
    left: 50%;
    transform: translate(-50%, -50%);
    @media only screen and (max-width: 767px){
        bottom: 30%;
    }
}
.title{
    justify-content: center;
}
.pad-left-right{
    padding: 0 1%;
}
.front-graphic{
    z-index: 0;
    margin-top: 6rem;
    height: auto;
    width: 100%;
    position: absolute;
    top: 90%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 25px;
}
`
export default Banner